import { createVuetify } from "vuetify";
//------------------------------------------------------ import { md3 } from 'vuetify/blueprints'
import "@mdi/font/css/materialdesignicons.css";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import PerfectScrollbar from "vue3-perfect-scrollbar";
//------------------------------------------------------ import VueApexCharts from "vue3-apexcharts";
import VueTablerIcons from "vue-tabler-icons";
//------------------------------------------------------ Mock Api data
import "../_mockApis";
//------------------------------------------------------ import VCalendar from "v-calendar";
import Maska from "maska";
import "vue3-carousel/dist/carousel.css";
import "@/assets/scss/style.scss";
//------------------------------------------------------ DragScroll
import { VueDraggableNext } from "vue-draggable-next";
//------------------------------------------------------ Table
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
//------------------------------------------------------ i18
import { createI18n } from "vue-i18n";
import messages from "@/utils/locales/messages";
import { BLUE_THEME } from "@/theme/LightTheme";
import { DARK_BLUE_THEME } from "@/theme/DarkTheme";

export let userRole: string | undefined;
export let t: (v: string) => string
export default defineNuxtPlugin((nuxtApp: any) => {
  const vuetify = createVuetify({
    ssr: true,
    //blueprint: md3,
    components: {
      draggable: VueDraggableNext,
      ...components,
    },
    directives,
    theme: {
      defaultTheme: "BLUE_THEME",
      themes: { BLUE_THEME, DARK_BLUE_THEME },
    },
    defaults: {
      VCard: { rounded: "md", elevation:10 },
      VTextField: { variant: "outlined", density: "comfortable", color: "primary" },
      VTextarea: { color: "primary", variant: "outlined", density: "comfortable" },
      VSelect: { variant: "outlined", density: "comfortable", color: "primary" },
      VListItem: { minHeight: "45px" },
      VTooltip: { location: "top" },
      VBtn: { style: "text-transform: capitalize; letter-spacing:0", rounded: "md" },
    },
  });
  
  const user = useStrapiUser()
  userRole = user.value?.user_15
  
  const i18n = createI18n({
    locale: user.value?.user_12,
    messages: messages,
    silentTranslationWarn: true,
    silentFallbackWarn: true,
    numberFormats: {
      'es-MX': { 
        currency: { style: 'currency', currency: 'MXN' },
        decimal: { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 },
      }
    }
  });

  t = i18n.global.t

  nuxtApp.vueApp.use(vuetify);
  nuxtApp.vueApp.component("EasyDataTable", Vue3EasyDataTable);
  nuxtApp.vueApp.use(PerfectScrollbar);
  //nuxtApp.vueApp.use(VueApexCharts);
  nuxtApp.vueApp.use(VueTablerIcons);
  nuxtApp.vueApp.use(Maska);
  nuxtApp.vueApp.use(i18n);
});
