/*import tabler icons*/
import { ArticleIcon, CheckboxIcon, BrandWhatsappIcon, MailIcon, TruckDeliveryIcon, HandStopIcon } from 'vue-tabler-icons';
import type { notificationTabType } from '@/types/NotificationType';
const notificationTb: notificationTabType[] = [
    {
        title: 'GuestConfirmation',
        subtitle: 'GuestConfirmationDescription',
        icon: CheckboxIcon,
        switch: false
    },
    {
        title: 'NewsConfirmation',
        subtitle: 'NewsConfirmationDescription',
        icon: ArticleIcon,
        switch: true
    },
    {
        title: 'DeliveryConfirmation',
        subtitle: 'DeliveryConfirmationDescription',
        icon: TruckDeliveryIcon,
        switch: false
    },
    {
        title: 'NotificationEmail',
        subtitle: 'NotificationEmailDescription',
        icon: MailIcon,
        switch: false
    },
    {
        title: 'WhatsappNotification',
        subtitle: 'WhatsappNotificationDescription',
        icon: BrandWhatsappIcon,
        switch: true
    },
    {
        title: 'NotDisturb',
        subtitle: 'NotDisturbDescription',
        icon: HandStopIcon,
        switch: true
    }
];

export {notificationTb};
