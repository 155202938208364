// project imports
import mock from './mockAdapter';

import type { notificationType, profileType, languageType, appsLinkType, quickLinksType,searchType } from '@/types/HeaderTypes'
// 
// Notification
// 
import user1 from '/images/profile/user-1.png';
import user2 from '/images/profile/user-2.png';

const notifications:notificationType[] = [
    
];

// 
// Profile 
// 
import proUser1 from '/images/svgs/icon-account.svg';
import proUser2 from '/images/svgs/icon-inbox.svg';
import proUser3 from '/images/svgs/icon-tasks.svg';
const profileDD: profileType[] = [
    {
        avatar: proUser1,
        title: 'Cuenta',
        subtitle: 'Configuraciones',
        href: '/account-settings'
    },
];

// 
// Language
// 
import flag1 from '/images/flag/icon-flag-en.svg';
import flag2 from '/images/flag/icon-flag-mx.svg';
const languageDD: languageType[] = [
    { title: 'English', subtext: 'EN', value: 'en', avatar: flag1 },
    { title: 'Español', subtext: 'ES', value: 'es', avatar: flag2 }
];

// 
// AppsLink
// 
import img8 from '/images/svgs/icon-dd-application.svg';
const appsLink: appsLinkType[] = [
    {
        avatar: img8,
        title: 'Medic',
        subtext: 'LearnInformation',
        href: 'https://www.liah-automation.com/medic'
    },
    {
        avatar: img8,
        title: 'Dentist',
        subtext: 'LearnInformation',
        href: 'https://www.liah-automation.com/dentist'
    },
    {
        avatar: img8,
        title: 'Logistic',
        subtext: 'LearnInformation',
        href: 'https://www.liah-automation.com/logistic'
    },
    {
        avatar: img8,
        title: 'Warehouse',
        subtext: 'LearnInformation',
        href: 'https://www.liah-automation.com/warehouse'
    },
    {
        avatar: img8,
        title: 'Schools',
        subtext: 'LearnInformation',
        href: 'https://www.liah-automation.com/schools'
    },
    {
        avatar: img8,
        title: 'Residential',
        subtext: 'LearnInformation',
        href: 'https://www.liah-automation.com/residential'
    },
    {
        avatar: img8,
        title: 'E-commerce',
        subtext: 'LearnInformation',
        href: 'https://www.liah-automation.com/e-commerce'
    },
    {
        avatar: img8,
        title: 'Hardware',
        subtext: 'LearnInformation',
        href: 'https://www.liah-automation.com/hardware'
    }
    ,
    {
        avatar: img8,
        title: 'Security',
        subtext: 'LearnInformation',
        href: 'https://www.liah-automation.com/security'
    },
    {
        avatar: img8,
        title: 'Billing',
        subtext: 'LearnInformation',
        href: 'https://www.liah-automation.com/billing'
    }
];

// 
// Quick Links
// 
const quickLink: quickLinksType[] = [
    {
        title: 'LIAH',
        href: 'https://www.liah-automation.com'
    },
    {
        title: 'Aplicaciones',
        href: 'https://www.liah-automation.com/applications'
    },
    {
        title: 'Automatizacion',
        href: 'https://www.liah-automation.com/automation'
    },
    {
        title: 'Contactanos',
        href: 'https://www.liah-automation.com/contact'
    },
    
];

// 
// Search Data
// 
const searchSugg: searchType[] = [
    {
        title: 'Modern',
        href: ''
    },
    {
        title: 'eCommerce',
        href: ''
    },
    {
        title: 'Contacts',
        href: ''
    },
    {
        title: 'Shop',
        href: ''
    },
    {
        title: 'Checkout',
        href: ''
    },
    {
        title: 'Chats',
        href: ''
    },
    {
        title: 'Notes',
        href: ''
    },
    {
        title: 'Pricing',
        href: ''
    },
    {
        title: 'Account Setting',
        href: ''
    },
];

export { notifications, profileDD, languageDD, appsLink, quickLink, searchSugg };

