import { default as indexAn5cBrUxjVMeta } from "/cloudclusters/LIAH_residencial/pages/account-settings/index.vue?macro=true";
import { default as edit_91id_93s6yV15nnsdMeta } from "/cloudclusters/LIAH_residencial/pages/arg100/arg110/edit[id].vue?macro=true";
import { default as mainZLmeLWcdlGMeta } from "/cloudclusters/LIAH_residencial/pages/arg100/arg110/main.vue?macro=true";
import { default as edit_91id_93TRGo592wHXMeta } from "/cloudclusters/LIAH_residencial/pages/arg100/arg120/edit[id].vue?macro=true";
import { default as mainxsMHmotOWzMeta } from "/cloudclusters/LIAH_residencial/pages/arg100/arg120/main.vue?macro=true";
import { default as edit_91id_93MB5Y83GpKhMeta } from "/cloudclusters/LIAH_residencial/pages/arg100/arg130/edit[id].vue?macro=true";
import { default as maine3sKZwEffHMeta } from "/cloudclusters/LIAH_residencial/pages/arg100/arg130/main.vue?macro=true";
import { default as edit_91id_93noKhSrs0zaMeta } from "/cloudclusters/LIAH_residencial/pages/arg100/arg140/edit[id].vue?macro=true";
import { default as mainUJ5c5A0w0wMeta } from "/cloudclusters/LIAH_residencial/pages/arg100/arg140/main.vue?macro=true";
import { default as edit_91id_93WvpVzaYYKzMeta } from "/cloudclusters/LIAH_residencial/pages/arg100/arg150/edit[id].vue?macro=true";
import { default as mainmEAijGAaicMeta } from "/cloudclusters/LIAH_residencial/pages/arg100/arg150/main.vue?macro=true";
import { default as edit_91id_93mBbshMAnuhMeta } from "/cloudclusters/LIAH_residencial/pages/arg100/arg160/edit[id].vue?macro=true";
import { default as mainRoGZla5DO0Meta } from "/cloudclusters/LIAH_residencial/pages/arg100/arg160/main.vue?macro=true";
import { default as mainIO4ADQ22SJMeta } from "/cloudclusters/LIAH_residencial/pages/arg100/arg170/main.vue?macro=true";
import { default as edit_91id_93YlWnvDrbnBMeta } from "/cloudclusters/LIAH_residencial/pages/arg100/arg180/edit[id].vue?macro=true";
import { default as quote_91id_93v2c6rjs67TMeta } from "/cloudclusters/LIAH_residencial/pages/arg100/arg180/quote[id].vue?macro=true";
import { default as table_91id_93XSwFB68zHLMeta } from "/cloudclusters/LIAH_residencial/pages/arg100/arg180/table[id].vue?macro=true";
import { default as edit_91id_93hLhv87uY6AMeta } from "/cloudclusters/LIAH_residencial/pages/arg100/arg190/edit[id].vue?macro=true";
import { default as mainuvHeH8CY3rMeta } from "/cloudclusters/LIAH_residencial/pages/arg100/arg190/main.vue?macro=true";
import { default as loginXfKXVtKacRMeta } from "/cloudclusters/LIAH_residencial/pages/auth/login.vue?macro=true";
import { default as consumosp3Wiu2UTNEMeta } from "/cloudclusters/LIAH_residencial/pages/dashboards/consumos.vue?macro=true";
import { default as residencialCWkE731dUOMeta } from "/cloudclusters/LIAH_residencial/pages/dashboards/residencial.vue?macro=true";
import { default as indexfiIom29lAFMeta } from "/cloudclusters/LIAH_residencial/pages/index.vue?macro=true";
import { default as edit_91id_93F92u9e1DXxMeta } from "/cloudclusters/LIAH_residencial/pages/user/edit[id].vue?macro=true";
import { default as tablefb6PccweM2Meta } from "/cloudclusters/LIAH_residencial/pages/user/table.vue?macro=true";
export default [
  {
    name: indexAn5cBrUxjVMeta?.name ?? "account-settings",
    path: indexAn5cBrUxjVMeta?.path ?? "/account-settings",
    meta: indexAn5cBrUxjVMeta || {},
    alias: indexAn5cBrUxjVMeta?.alias || [],
    redirect: indexAn5cBrUxjVMeta?.redirect,
    component: () => import("/cloudclusters/LIAH_residencial/pages/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: edit_91id_93s6yV15nnsdMeta?.name ?? "arg100-arg110-editid",
    path: edit_91id_93s6yV15nnsdMeta?.path ?? "/arg100/arg110/edit:id()",
    meta: edit_91id_93s6yV15nnsdMeta || {},
    alias: edit_91id_93s6yV15nnsdMeta?.alias || [],
    redirect: edit_91id_93s6yV15nnsdMeta?.redirect,
    component: () => import("/cloudclusters/LIAH_residencial/pages/arg100/arg110/edit[id].vue").then(m => m.default || m)
  },
  {
    name: mainZLmeLWcdlGMeta?.name ?? "arg100-arg110-main",
    path: mainZLmeLWcdlGMeta?.path ?? "/arg100/arg110/main",
    meta: mainZLmeLWcdlGMeta || {},
    alias: mainZLmeLWcdlGMeta?.alias || [],
    redirect: mainZLmeLWcdlGMeta?.redirect,
    component: () => import("/cloudclusters/LIAH_residencial/pages/arg100/arg110/main.vue").then(m => m.default || m)
  },
  {
    name: edit_91id_93TRGo592wHXMeta?.name ?? "arg100-arg120-editid",
    path: edit_91id_93TRGo592wHXMeta?.path ?? "/arg100/arg120/edit:id()",
    meta: edit_91id_93TRGo592wHXMeta || {},
    alias: edit_91id_93TRGo592wHXMeta?.alias || [],
    redirect: edit_91id_93TRGo592wHXMeta?.redirect,
    component: () => import("/cloudclusters/LIAH_residencial/pages/arg100/arg120/edit[id].vue").then(m => m.default || m)
  },
  {
    name: mainxsMHmotOWzMeta?.name ?? "arg100-arg120-main",
    path: mainxsMHmotOWzMeta?.path ?? "/arg100/arg120/main",
    meta: mainxsMHmotOWzMeta || {},
    alias: mainxsMHmotOWzMeta?.alias || [],
    redirect: mainxsMHmotOWzMeta?.redirect,
    component: () => import("/cloudclusters/LIAH_residencial/pages/arg100/arg120/main.vue").then(m => m.default || m)
  },
  {
    name: edit_91id_93MB5Y83GpKhMeta?.name ?? "arg100-arg130-editid",
    path: edit_91id_93MB5Y83GpKhMeta?.path ?? "/arg100/arg130/edit:id()",
    meta: edit_91id_93MB5Y83GpKhMeta || {},
    alias: edit_91id_93MB5Y83GpKhMeta?.alias || [],
    redirect: edit_91id_93MB5Y83GpKhMeta?.redirect,
    component: () => import("/cloudclusters/LIAH_residencial/pages/arg100/arg130/edit[id].vue").then(m => m.default || m)
  },
  {
    name: maine3sKZwEffHMeta?.name ?? "arg100-arg130-main",
    path: maine3sKZwEffHMeta?.path ?? "/arg100/arg130/main",
    meta: maine3sKZwEffHMeta || {},
    alias: maine3sKZwEffHMeta?.alias || [],
    redirect: maine3sKZwEffHMeta?.redirect,
    component: () => import("/cloudclusters/LIAH_residencial/pages/arg100/arg130/main.vue").then(m => m.default || m)
  },
  {
    name: edit_91id_93noKhSrs0zaMeta?.name ?? "arg100-arg140-editid",
    path: edit_91id_93noKhSrs0zaMeta?.path ?? "/arg100/arg140/edit:id()",
    meta: edit_91id_93noKhSrs0zaMeta || {},
    alias: edit_91id_93noKhSrs0zaMeta?.alias || [],
    redirect: edit_91id_93noKhSrs0zaMeta?.redirect,
    component: () => import("/cloudclusters/LIAH_residencial/pages/arg100/arg140/edit[id].vue").then(m => m.default || m)
  },
  {
    name: mainUJ5c5A0w0wMeta?.name ?? "arg100-arg140-main",
    path: mainUJ5c5A0w0wMeta?.path ?? "/arg100/arg140/main",
    meta: mainUJ5c5A0w0wMeta || {},
    alias: mainUJ5c5A0w0wMeta?.alias || [],
    redirect: mainUJ5c5A0w0wMeta?.redirect,
    component: () => import("/cloudclusters/LIAH_residencial/pages/arg100/arg140/main.vue").then(m => m.default || m)
  },
  {
    name: edit_91id_93WvpVzaYYKzMeta?.name ?? "arg100-arg150-editid",
    path: edit_91id_93WvpVzaYYKzMeta?.path ?? "/arg100/arg150/edit:id()",
    meta: edit_91id_93WvpVzaYYKzMeta || {},
    alias: edit_91id_93WvpVzaYYKzMeta?.alias || [],
    redirect: edit_91id_93WvpVzaYYKzMeta?.redirect,
    component: () => import("/cloudclusters/LIAH_residencial/pages/arg100/arg150/edit[id].vue").then(m => m.default || m)
  },
  {
    name: mainmEAijGAaicMeta?.name ?? "arg100-arg150-main",
    path: mainmEAijGAaicMeta?.path ?? "/arg100/arg150/main",
    meta: mainmEAijGAaicMeta || {},
    alias: mainmEAijGAaicMeta?.alias || [],
    redirect: mainmEAijGAaicMeta?.redirect,
    component: () => import("/cloudclusters/LIAH_residencial/pages/arg100/arg150/main.vue").then(m => m.default || m)
  },
  {
    name: edit_91id_93mBbshMAnuhMeta?.name ?? "arg100-arg160-editid",
    path: edit_91id_93mBbshMAnuhMeta?.path ?? "/arg100/arg160/edit:id()",
    meta: edit_91id_93mBbshMAnuhMeta || {},
    alias: edit_91id_93mBbshMAnuhMeta?.alias || [],
    redirect: edit_91id_93mBbshMAnuhMeta?.redirect,
    component: () => import("/cloudclusters/LIAH_residencial/pages/arg100/arg160/edit[id].vue").then(m => m.default || m)
  },
  {
    name: mainRoGZla5DO0Meta?.name ?? "arg100-arg160-main",
    path: mainRoGZla5DO0Meta?.path ?? "/arg100/arg160/main",
    meta: mainRoGZla5DO0Meta || {},
    alias: mainRoGZla5DO0Meta?.alias || [],
    redirect: mainRoGZla5DO0Meta?.redirect,
    component: () => import("/cloudclusters/LIAH_residencial/pages/arg100/arg160/main.vue").then(m => m.default || m)
  },
  {
    name: mainIO4ADQ22SJMeta?.name ?? "arg100-arg170-main",
    path: mainIO4ADQ22SJMeta?.path ?? "/arg100/arg170/main",
    meta: mainIO4ADQ22SJMeta || {},
    alias: mainIO4ADQ22SJMeta?.alias || [],
    redirect: mainIO4ADQ22SJMeta?.redirect,
    component: () => import("/cloudclusters/LIAH_residencial/pages/arg100/arg170/main.vue").then(m => m.default || m)
  },
  {
    name: edit_91id_93YlWnvDrbnBMeta?.name ?? "arg100-arg180-editid",
    path: edit_91id_93YlWnvDrbnBMeta?.path ?? "/arg100/arg180/edit:id()",
    meta: edit_91id_93YlWnvDrbnBMeta || {},
    alias: edit_91id_93YlWnvDrbnBMeta?.alias || [],
    redirect: edit_91id_93YlWnvDrbnBMeta?.redirect,
    component: () => import("/cloudclusters/LIAH_residencial/pages/arg100/arg180/edit[id].vue").then(m => m.default || m)
  },
  {
    name: quote_91id_93v2c6rjs67TMeta?.name ?? "arg100-arg180-quoteid",
    path: quote_91id_93v2c6rjs67TMeta?.path ?? "/arg100/arg180/quote:id()",
    meta: quote_91id_93v2c6rjs67TMeta || {},
    alias: quote_91id_93v2c6rjs67TMeta?.alias || [],
    redirect: quote_91id_93v2c6rjs67TMeta?.redirect,
    component: () => import("/cloudclusters/LIAH_residencial/pages/arg100/arg180/quote[id].vue").then(m => m.default || m)
  },
  {
    name: table_91id_93XSwFB68zHLMeta?.name ?? "arg100-arg180-tableid",
    path: table_91id_93XSwFB68zHLMeta?.path ?? "/arg100/arg180/table:id()",
    meta: table_91id_93XSwFB68zHLMeta || {},
    alias: table_91id_93XSwFB68zHLMeta?.alias || [],
    redirect: table_91id_93XSwFB68zHLMeta?.redirect,
    component: () => import("/cloudclusters/LIAH_residencial/pages/arg100/arg180/table[id].vue").then(m => m.default || m)
  },
  {
    name: edit_91id_93hLhv87uY6AMeta?.name ?? "arg100-arg190-editid",
    path: edit_91id_93hLhv87uY6AMeta?.path ?? "/arg100/arg190/edit:id()",
    meta: edit_91id_93hLhv87uY6AMeta || {},
    alias: edit_91id_93hLhv87uY6AMeta?.alias || [],
    redirect: edit_91id_93hLhv87uY6AMeta?.redirect,
    component: () => import("/cloudclusters/LIAH_residencial/pages/arg100/arg190/edit[id].vue").then(m => m.default || m)
  },
  {
    name: mainuvHeH8CY3rMeta?.name ?? "arg100-arg190-main",
    path: mainuvHeH8CY3rMeta?.path ?? "/arg100/arg190/main",
    meta: mainuvHeH8CY3rMeta || {},
    alias: mainuvHeH8CY3rMeta?.alias || [],
    redirect: mainuvHeH8CY3rMeta?.redirect,
    component: () => import("/cloudclusters/LIAH_residencial/pages/arg100/arg190/main.vue").then(m => m.default || m)
  },
  {
    name: loginXfKXVtKacRMeta?.name ?? "auth-login",
    path: loginXfKXVtKacRMeta?.path ?? "/auth/login",
    meta: loginXfKXVtKacRMeta || {},
    alias: loginXfKXVtKacRMeta?.alias || [],
    redirect: loginXfKXVtKacRMeta?.redirect,
    component: () => import("/cloudclusters/LIAH_residencial/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: consumosp3Wiu2UTNEMeta?.name ?? "dashboards-consumos",
    path: consumosp3Wiu2UTNEMeta?.path ?? "/dashboards/consumos",
    meta: consumosp3Wiu2UTNEMeta || {},
    alias: consumosp3Wiu2UTNEMeta?.alias || [],
    redirect: consumosp3Wiu2UTNEMeta?.redirect,
    component: () => import("/cloudclusters/LIAH_residencial/pages/dashboards/consumos.vue").then(m => m.default || m)
  },
  {
    name: residencialCWkE731dUOMeta?.name ?? "dashboards-residencial",
    path: residencialCWkE731dUOMeta?.path ?? "/dashboards/residencial",
    meta: residencialCWkE731dUOMeta || {},
    alias: residencialCWkE731dUOMeta?.alias || [],
    redirect: residencialCWkE731dUOMeta?.redirect,
    component: () => import("/cloudclusters/LIAH_residencial/pages/dashboards/residencial.vue").then(m => m.default || m)
  },
  {
    name: indexfiIom29lAFMeta?.name ?? "index",
    path: indexfiIom29lAFMeta?.path ?? "/",
    meta: indexfiIom29lAFMeta || {},
    alias: indexfiIom29lAFMeta?.alias || [],
    redirect: indexfiIom29lAFMeta?.redirect,
    component: () => import("/cloudclusters/LIAH_residencial/pages/index.vue").then(m => m.default || m)
  },
  {
    name: edit_91id_93F92u9e1DXxMeta?.name ?? "user-editid",
    path: edit_91id_93F92u9e1DXxMeta?.path ?? "/user/edit:id()",
    meta: edit_91id_93F92u9e1DXxMeta || {},
    alias: edit_91id_93F92u9e1DXxMeta?.alias || [],
    redirect: edit_91id_93F92u9e1DXxMeta?.redirect,
    component: () => import("/cloudclusters/LIAH_residencial/pages/user/edit[id].vue").then(m => m.default || m)
  },
  {
    name: tablefb6PccweM2Meta?.name ?? "user-table",
    path: tablefb6PccweM2Meta?.path ?? "/user/table",
    meta: tablefb6PccweM2Meta || {},
    alias: tablefb6PccweM2Meta?.alias || [],
    redirect: tablefb6PccweM2Meta?.redirect,
    component: () => import("/cloudclusters/LIAH_residencial/pages/user/table.vue").then(m => m.default || m)
  }
]